import React from "react";
import Modal from "react-bootstrap/Modal";
import "./NewProjectModal.scss";
import { createProjectLeft } from "../../../assets/images";
import Form from "react-bootstrap/Form";
import { Button } from "../../Button";
import { useHistory } from "react-router-dom";

function NewProjectModal(props) {
  const { onHide } = props;
  const history = useHistory();

  const toCreateProject = (e) => {
    onHide();
    history.push("/dashboard/createProject");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-left">
          <img
            src={createProjectLeft}
            alt="imgmodal"
            style={{ width: "100%", background: "#fff9f4" }}
          ></img>
        </div>
        <div className="modal-right">
          <h3>Project Type</h3>
          <Form.Group className="mb-3  btn-group" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Single Factor Authentication" />
            <Form.Check type="checkbox" label=" 2 Factor Authentication " />
            <Form.Check type="checkbox" label="3 Factor Authentication " />
            <Form.Check type="checkbox" label="4 Factor Authentication " />
          </Form.Group>
          <div className="modal-btn-div">
            <Button name="Continue" onclick={toCreateProject}></Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { NewProjectModal };
