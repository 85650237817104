import React, { useState, useEffect } from "react";
import { Header } from "../../components/Header/Header";
import "./Dashboard.scss";
import { dashboardWelcome } from "../../assets/images";
import { DashboardTable } from "../../components";
import { Doughnut } from "react-chartjs-2";
import { apiGetLogin } from "../../api/login";
import { apiFetchProjects } from "../../api/projects";
import { apiScanStats } from "../../api/scans";

function Dashboard() {
  const [firstName, setFirstName] = useState("");
  const [circularData, setCircularData] = useState([0, 0, 0]);
  const [successes, setSuccesses] = useState(0);
  const [failures, setFailures] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    apiGetLogin(setLogin);
    apiFetchProjects(setProjectStats);
    apiScanStats(-1, setScanStats);
  }, []);

  const setLogin = (vals) => {
    setFirstName(vals.first_name);
  };

  const setProjectStats = (projects) => {
    var processing = 0;
    var inactive = 0;
    var test = 0;
    var active = 0;
    projects.forEach((el) => {
      if (el.id === -1) {
      } else if (el.provisioning_status === "PROCESSING") {
        processing += 1;
      } else if (el.state === "INACTIVE") {
        inactive += 1;
      } else if (el.state === "TEST") {
        test += 1;
      } else {
        active += 1;
      }
    });

    setCircularData([active, inactive, processing + test]);
  };

  const setScanStats = (stats) => {
    setTotal(stats.total);
    setSuccesses(stats.successes);
    setFailures(stats.failures);
  };

  return (
    <div className="main-dashboard">
      <div className="d-header">
        <Header title="Dashboard" />
      </div>
      <div className="row">
        <div className="col-md-8 cd-sm-12">
          <div className="d-wmsg">
            <div className="welcome-msg">
              <div className="w-div-1">
                <h1>Welcome back, {firstName}</h1>
                <p>
                  Take a look at your current projects or create a new project!
                </p>
                <a href="/dashboard/live"> View Live Projects</a>
              </div>
              {/*
              <img
                src={dashboardWelcome}
                className="welcom-img"
                alt="welcome"
              />
              */}
              {/* <div className="w-div-2"></div> */}
            </div>
          </div>
          <div>
            <div className="statistics-div">
              <p className="a-p">Daily Statistics</p>
              <div className="s-div-2">
                <div className="s-div color-1 ">
                  <p className="a-p-3">{total}</p>
                  <p className="a-p-2">Scanned Today</p>
                </div>
                <div className="s-div color-2">
                  <p className="a-p-3">{successes}</p>
                  <p className="a-p-2">Scans Passed</p>
                </div>
                <div className="s-div color-3">
                  <p className="a-p-3">{failures}</p>
                  <p className="a-p-2">Scans Failed</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="recent-scans">
              <DashboardTable />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="activity">
            <div className="graph-div">
              <p className="activity-heading">Activity</p>
              <p className="a-p">Project Status</p>
              <div className="chart-alignment">
                <div className="chart-div">
                  <Doughnut
                    width={100}
                    height={100}
                    options={{
                      legend: {
                        display: false,
                      },
                      cutoutPercentage: 60,
                      rotation: 150,
                      responsive: true,

                      plugins: {
                        doughnutlabel: {
                          labels: [
                            {
                              text: "550",
                              font: {
                                size: 20,
                                weight: "bold",
                                color: "red",
                              },
                              position: "bottom",
                            },

                            {
                              text: "total",
                            },
                          ],
                        },
                      },
                    }}
                    data={{
                      labels: ["Live", "Inactive", "Processing"],

                      datasets: [
                        {
                          data: circularData,
                          rotation: 100,
                          backgroundColor: ["green", "#4FBAF0", "#0060f0"],
                          borderAlign: "inner",
                          borderCapStyle: "round",
                          label: false,
                          borderJoinStyle: "round",
                          radius: 20,
                          hoverBackgroundColor: [
                            "green",
                            "#4FBAF0",
                            "#0060f0",
                          ],
                          hoverBorderWidth: [1, 1, 1],
                        },
                      ],
                      radius: 10,
                    }}
                  />
                </div>
                <div className="chart-center">
                  <span>100%</span>
                </div>
              </div>
              <ul>
                <li className="live">
                  {" "}
                  <span> {circularData[0]} </span> <br /> <span>Live</span>
                </li>
                <li className="processing">
                  {" "}
                  <span> {circularData[2]} </span> <br />
                  <span>Processing</span>
                </li>
                <li className="inactive">
                  {" "}
                  <span> {circularData[1]} </span> <br /> <span>Inactive</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="map-div">
            {/* <p className="a-p">Daily Statistics</p>
              <div className="s-div-1 ">
                <p className="a-p-2">Scanned Today</p>
                <p className="a-p-3">{total}</p>
              </div>
              <div className="s-div-2">
                <div className="s-div">
                  <p className="a-p-2">Scanned Passed</p>
                  <p className="a-p-3">{successes}</p>
                </div>
                <div className="s-div">
                  <p className="a-p-2">Scanned Failed</p>
                  <p className="a-p-3">{failures}</p>
                </div>
              </div> */}
            <div className="s-div3">
              <p className="a-p-2">Top Location</p>
              {/* <p className="a-p-3">{top}</p> */}
              <p className="a-p-3">Los Angeles</p>
            </div>
            <div className="map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423283.4355457752!2d-118.69191196832071!3d34.020730498733336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1610705482750!5m2!1sen!2s"
              ></iframe>
            </div>
          </div>
          {/* <div className="button-div">
            <NewProject />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export { Dashboard };
