export const data = [
  {
    id: 1,
    projectname: 'Woody',
    label: ' Label 13',
    description:
      'Campaign “TakeOff”. Encrtyped Buzz Lightyear logo on box, under buzz fight, and label.',
    range: '02',
    livescans: '25',
    status: 'Active',
    factor: 'Factor1',
  },
  {
    id: 2,
    projectname: 'Woody',
    label: ' Label 13',
    description:
      'Campaign “TakeOff”. Encrtyped Buzz Lightyear logo on box, under buzz fight, and label.',
    range: '02',
    livescans: '25',
    status: 'Active',
    factor: 'Factor 2',
  },
]
