import React, { Component } from 'react'
import { apiFetchScans } from '../../../api/scans'
import $ from 'jquery'
import './DashboardTable.scss'

class DashboardTable extends Component {
  constructor(props) {
    super(props)
    let activityH = $('.activity').outerHeight()
    let swgH = $('.d-wmsg').outerHeight()
    let tableHead = $('.dataTables_scrollHead').outerHeight()
    let tableTitle = $('.dashboardPage.firstTable .heading ').outerHeight()
    let dataH = activityH - swgH - tableHead - tableTitle - 35
    this.state = {
      Aheight: activityH,
      Sheight: swgH,
      Theight: dataH,
      scanData: [],
    }
    this.timer = null
    this.windowResized = this.windowResized.bind(this)
    this.updateWindowWidth = this.updateWindowWidth.bind(this)
    this.setScans = this.setScans.bind(this)
  }

  setScans(scans) {
    this.setState({
      scanData: scans.map((el) => (
        <tr key={el.id}>
          <td>{el.label === 'dummy' ? 'FAIL' : el.label}</td>
          <td>{el.end_user_id}</td>
          <td>{el.location}</td>
          <td>{el.timestamp}</td>
        </tr>
      )),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized)
    apiFetchScans(true, -1, this.setScans)
  }

  componentDidUpdate(prevProps, prevState) {
    $('#dashboardtable_wrapper .dataTables_scrollBody').css(
      'height',
      this.state.Theight,
    )
    $('#dashboardtable_wrapper .dataTables_scrollBody').css(
      'max-height',
      this.state.Theight,
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized)
  }

  updateWindowWidth() {
    let _this = this
    let NactivityH = $('.activity').outerHeight()
    let NswgH = $('.d-wmsg').outerHeight()
    let tableHead = $('.dataTables_scrollHead').outerHeight()
    let tableTitle = $('.dashboardPage.firstTable .heading ').outerHeight()

    setTimeout(function () {
      _this.setState({
        Theight: NactivityH - NswgH - tableHead - tableTitle - 35,
      })
    })
  }

  windowResized() {
    let _this = this
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(function () {
      _this.updateWindowWidth()
    }, 500)
  }

  render() {
    const { scanData } = this.state

    return (
      <div className="dashboardPage firstTable">
        <p className="heading">Recent Scans</p>
        <div className="table-scroll">
          <table id="dashboardtable" className="recentscantable">
            <thead>
              <tr>
                <th>Label</th>
                <th>Scanned by</th>
                <th>Location</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>{scanData}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

export { DashboardTable }
